* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
    --default-padding: 16px;
}

body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

footer {
    flex: 0 0 50px;
    margin-top: auto;
    position: relative;
    text-align: center;
    width:100%;
}

textarea,
select {
    padding: 10px 15px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.notification__form {
    width: 80%;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
}

.homeContainer h3,
textarea {
    margin-bottom: 20px;
}

.notification__form button {
    width: 200px;
    padding: 15px 10px;
}

.container {
    padding: var(--default-padding);
}

.button {
    padding: calc(var(--default-padding) / 2);
}

@font-face {
    font-family: "Poppins";
    src: url("../src/fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Poppins Bold";
    src: url("../src/fonts/Poppins-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@media (max-width: 599px) {
    .event-page-container {
        padding: 16px;
    }
    .event-info-buttons {
        justify-content: center;
    }
}

@media (min-width: 600px) {
    .event-image-web {
        margin-right: 16px;
    }
}